import { defineStore } from 'pinia';
import { firebase } from '@firebase/app';
import '@firebase/auth';
import router from '../router';

export const useUserStore = defineStore('user', {
	state: () => ({
		user: {
			loggedIn: false,
			data: null
		},
		loading: false
	}),
	getters: {
		userData(state) {
			return state.user;
		},
		isLoading(state) {
			return state.loading;
		}
	},
	actions: {
		async fetchUser() {
			try {
				const res = await firebase.auth().currentUser;
				this.setUser(res);
			} catch (e) {
				console.error(e);
			}
		},
		async loginUser(email, password) {
			this.loading = true;

			try {
				const res = await firebase.auth().signInWithEmailAndPassword(email, password);
				if (res) {
					this.setUser(res?.user);
				} else {
					throw new Error('login failed')
				}
			} catch(e) {
				console.error(e);
			} finally {
				this.loading = false;
			}
		},
		async logOut(){
			await firebase.auth().signOut();
			this.setUser(null);
			router.push('/login');
		},
		setUser(user) {
			this.user.data = user;
			this.user.loggedIn = this.user?.data ? true : false;
		}
	}
});
